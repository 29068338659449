<template>
  <div class="icon-bg">
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19 2H7C5.2536 2 4 3.30584 4 5V19C4 20.6942 5.2536 22 7 22H19C19.6418 22 20 21.6269 20 21V20H8C7.44772 20 7 19.5523 7 19C7 18.4477 7.44772 18 8 18H20V3C20 2.3731 19.6418 2 19 2Z"
        fill="#A7A9C0"
      />
      <mask
        id="mask0"
        style="mask-type: alpha"
        maskUnits="userSpaceOnUse"
        x="4"
        y="2"
        width="16"
        height="20"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M19 2H7C5.2536 2 4 3.30584 4 5V19C4 20.6942 5.2536 22 7 22H19C19.6418 22 20 21.6269 20 21V20H8C7.44772 20 7 19.5523 7 19C7 18.4477 7.44772 18 8 18H20V3C20 2.3731 19.6418 2 19 2Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0)"></g>
    </svg>
  </div>
</template>

<script>
export default {
  name: "IconSection",
  mixins: [],
  props: {},
  components: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {},
  computed: {},
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
.icon-bg {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  flex-shrink: 0;
  transition: 0.1s;
  svg {
    width: 24px;
    height: 24px;
  }
  @media (hover: hover) {
    &:hover {
      background: #ebf2fa;
      svg {
        path {
          fill: #5551f9;
        }
      }
    }
  }
  &:active {
    background: transparent;
    svg {
      path {
        fill: #5551f9;
      }
    }
  }
}
</style>
